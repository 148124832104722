<template>
  <div>
    <component :is="layout">
      <div class="text-center">
        <div class="m-3 p-2 card rounded shadow">
          <h3 class="font-weight-bolder">Add Client</h3>
          <span v-if="message">
              <p class="alert-success">{{message}}</p>
          </span>
          <span v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <p class="alert-danger" v-for="error in errors" :key="error">
              {{ error }}
            </p>
          </span>
          <div class="row justify-content-around">
            <div class="col-md-6 form-group">
              <label for="name">Name</label>
              <input
                v-model="client.name"
                placeholder="name"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col-md-6 form-group">
              <label for="email">Email</label>
              <input
                v-model="client.email"
                placeholder="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="row justify-content-around">
            <div class="col-md-6 form-group">
              <label for="name">Password</label>
              <input
                v-model="client.password"
                placeholder="password"
                type="password"
                class="form-control"
              />
            </div>
            <div class="col-md-6 form-group">
              <label for="email">Day Game</label>
              <input
                v-model="client.dailyGame"
                placeholder="Day Game"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="row justify-content-around align-items-end">
            <div class="col-md-6 form-group">
              <label for="name">Select Panel</label>
              <select v-model="client.panel" class="form-control">
                <option value="5">5 Day Panel</option>
                <option value="6">6 Day Panel</option>
                <option value="7">7 Day Panel</option>
              </select>
            </div>
            <div class="col-md-6 form-group">
              <label for="email">Time</label>
              <input
                v-model="client.time"
                placeholder="Time"
                type="text"
                class="form-control"
              />
            </div>
            <div>
              <button
                @click="addClient()"
                class="my-3 w-100 btn btn-block btn-success"
              >
                Add CLient
              </button>
            </div>
          </div>
        </div>
        <div class="m-3 p-2 card rounded shadow">
          <table class="table table-responsive table-striped">
            <tr>
              <td>id</td>
              <td>name</td>
              <td>email</td>
              <td>status</td>
            </tr>
            <tr v-for="client in clients" :key="client.id">
              <td>{{ client.id }}</td>
              <td>{{ client.name }}</td>
              <td>{{ client.email }}</td>
              <td @click="toggleClientStatus(client.id)" class="m-1 btn bg-success btn-sm btn-success" v-if="client.status">Activated</td>
              <td v-else><button @click="toggleClientStatus(client.id)" class="m-1 bg-danger btn btn-sm btn-danger">Deactivated</button></td>
            </tr>
          </table>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import axiosObject from '../../axiosHandler';
import axiosConfig from '../../axiosConfig';
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      clients: Array,
      client: {
        name: null,
        email: null,
        password: null,
        dailyGame: null,
        time: null,
        panel: null,
      },
      errors: [],
      message:null
    };
  },
  created() {
    this.getClients();
  },
  methods: {
    addClient() {
      if (this.checkForm()) {
        axiosObject
          .post("/api/admin/client", this.client,axiosConfig)
          .then(
            function (response) {
              Object.keys(this.client).forEach(key => this.client[key]=null);
              this.message = response.data.message
              this.getClients();
            }.bind(this)
          )
          .catch(function (error) {
           alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
          });
      }
    },
    checkForm() {
      this.errors = [];
      if (!this.client.name) {
        this.errors.push("Name required.");
      } else if (!this.client.email) {
        this.errors.push("Email required.");
      } else if (!this.client.password) {
        this.errors.push("Password required.");
      } else if (!this.client.dailyGame) {
        this.errors.push("Game required.");
      } else if (!this.client.time) {
        this.errors.push("Time required.");
      } else if (!this.client.panel) {
        this.errors.push("Panel required.");
      }

      if (!this.errors.length) {
        return true;
      }
      return false;
    },
    getClients() {
      axiosObject.get("/api/admin/get_clients",axiosConfig)
        .then(
          function (response) {
            this.clients = response.data.clients;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    toggleClientStatus(id)
    {
       axiosObject.post('/api/admin/toggle_client_status/'+id,axiosConfig).then( function(response){
         this.getClients()
       }.bind(this)).catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    }
  },
};
</script>

<style>
</style>
